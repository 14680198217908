import React, { useState } from "react";
import './App.css';
import "./media/js/script";
import Header from './components/Header/Header';
import Newsletter from './components/Newsletter/Newsletter';
import Services from './components/Services/Services';
import Work from './components/Work/Work';
import WorkProcess from './components/WorkProcess/WorkProcess';
import About from './components/About/About';
import Testimonials from './components/Testimonials/Testimonials';
import Contact from './components/Contact/Contact';
import Footer from './components/Footer/Footer';
import ScrollToTop from "react-scroll-to-top";

function App() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };
  return (
    <div className="App">
      <Header />
      {/* <Services /> */}
      <Work onModalOpen={handleModalOpen} />
      {/* <WorkProcess /> */}
      {/* <Newsletter /> */}
      <About />
      <Testimonials />
      <Contact />
      {/* <Footer /> */}
      {!isModalOpen && (
        <ScrollToTop width="20" viewBox="0 0 256 256" smooth />
      )}
    </div>
  );
}

export default App;
