import React from 'react';
import "./About.css";
import images from "../../constants/images";

const About = () => {
  return (
    <div className='about section-p' id="about">
        <div className='container'>
            <div className='about-content'>
                <div className='about-grid grid'>
                    <img src = {images.About_Alex} alt = "" className='about-img mx-auto' />
                    <div className='section-title'>
                    {/* <h3 className='text-brown'>Jestem <span className='text-dark'>Aleksandra</span></h3> */}
                        <p className='text mx-auto'><b>Aleksandra Kmita</b>, ur. 1999, mieszka i studiuje w Łodzi. <br /><br /> Studentka studiów magisterskich kierunku edukacja artystyczna <br />w  zakresie sztuk plastycznych na Akademii Sztuk Pięknych <br /> im. Władysława Strzemińskiego w Łodzi. Kmita czerpie inspiracje ze sztuki i kultury Dalekiego Wschodu, a jej prace koncentrują się na otaczającym ją środowisku, badając jednocześnie związki z mitami <br /> i przypowieściami różnych kultur. <br /> Istotną rolę w jej twórczości odgrywa celowe wykorzystanie światła <br/> i cienia. W swoich projektach Kmita często łączy różne dziedziny sztuki, takie jak malarstwo, projektowanie kostiumów i scenografii multimedialnej. Lubi poszukiwać pomysłów na nietypowe media <br/> i narzędzia artystyczne wśród różnych materiałów. Dzięki dbałości <br/> o szczegóły, Kmita stara się tworzyć prace prowokujące do myślenia <br/>i wizualnie stymulujące, które stanowią wyzwanie dla percepcji tradycyjnych form sztuki.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default About