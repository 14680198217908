import React from 'react';
import "./Contact.css";
import { FaYoutube, FaFacebookF, FaInstagram, FaLinkedin } from 'react-icons/fa';

const Contact = () => {
  return (
    <div className='contact section-p bg-grey' id="contact">
        <div className='container'>
            <div className='contact-section'>
                <div className='section-title'>
                    <p className='text-brown'>Email: <span className='text-dark'> akmita.ws@gmail.com </span></p>
                </div>
                <ul className='footer-social-links flex flex-c fs-14'>
                    <li>
                        <a href = "https://www.instagram.com/akmita_/" target="_blank">
                            <FaInstagram className="instagram-icon" />
                        </a>
                    </li>
                    <li>
                        <a href = "https://www.facebook.com/profile.php?id=100090936743852 " className='text-black' target="_blank">
                            <FaFacebookF className="facebook-icon" />
                        </a>
                    </li>
                    <li>
                        <a href = "https://www.youtube.com/channel/UCfdNTjkgAWZR_FtCthlyfGA" className='text-black' target="_blank">
                            <FaYoutube className="youtube-icon" />
                        </a>
                    </li>
                    <li>
                        <a href = "https://www.linkedin.com/in/aleksandra-kmita-a84725227/?fbclid=IwAR0xU0HPf96IguTOHRo7Q0HHCcuUbQ4EFPnHP9wiAPc6MTEQmi9TiaIe448" className='text-black' target="_blank">
                            <FaLinkedin className="linkedin-icon" />
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
  )
}

export default Contact