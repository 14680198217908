import React, {useState, useEffect} from 'react';
import {works_kostium_i_scenografia} from "../../constants/data";
import {works_malarstwo} from "../../constants/data";
import {works_rysunek} from "../../constants/data";
import "./Work.css";
import {BsPlusLg, BsChevronLeft, BsChevronRight} from "react-icons/bs";
import {ImCancelCircle} from "react-icons/im";

const Work = () => {
    const [imageModal, setImageModal] = useState(false);
    const [imageSource, setImageSource] = useState("");
    const [showTitle, setTitle] = useState("");
    const [showDescription, setDescription] = useState("");
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const setImageOnModal = (src, index) => {
        setImageModal(true);
        setImageSource(src.image);
        setTitle(src.title);
        setDescription(src.description);
        setCurrentIndex(index);
    }

    const handleNext = () => {
        let nextIndex = currentIndex + 1;
        if (nextIndex > works_malarstwo.length - 1) {
            nextIndex = 0;
        }
        setImageSource(works_malarstwo[nextIndex].image);
        setTitle(works_malarstwo[nextIndex].title);
        setDescription(works_malarstwo[nextIndex].description);
        setCurrentIndex(nextIndex);
    }

    const handlePrev = () => {
        let prevIndex = currentIndex - 1;
        if (prevIndex < 0) {
            prevIndex = works_malarstwo.length - 1;
        }
        setImageSource(works_malarstwo[prevIndex].image);
        setTitle(works_malarstwo[prevIndex].title);
        setDescription(works_malarstwo[prevIndex].description);
        setCurrentIndex(prevIndex);
    }
    
    useEffect(() => {
        if (isModalOpen) {
            document.body.classList.add("no-scroll");
        } else {
            document.body.classList.remove("no-scroll");
        }
    }, [isModalOpen]);

    return (
        <div className='work section-p bg-grey' id = "work">
            <div className={imageModal ? "modal show-image-box" : "modal"}>
                <div class="modal-content">
                    <div className='image-box-close-btn text-center' onClick={() => {
                        setImageModal(false);
                        setIsModalOpen(false);
                    }}>
                        <ImCancelCircle className = "text-white text-center" size={30} />
                    </div>
                    <img class="modal-image" src = {imageSource} alt = "" />
                    <div class="modal-text">
                        <h2 class="modal-title text-brown">{showTitle}</h2>
                        <p class="modal-description text-white">{showDescription}</p>
                    </div>
                </div>
            </div>

        <div className='container'>
            <div className='work-content'>
                <div className='section-title'>
                    <h1 className='text-brown'>Prace</h1>
                </div>
                
                <div className='section-title'>
                    <h4 className='text-black'>Malarstwo</h4>
                </div>
                <div className='work-list grid'>
                    {
                        works_malarstwo.map((work, index) => {
                            return (
                                <div className='work-item text-center' key = {index} onClick={() => {
                                    setImageOnModal(work, index)
                                    setIsModalOpen(true);
                                }}>
                                    <img src = {work.image} alt = "" />
                                    <span className='work-item-icon'>
                                        <BsPlusLg size = {48} className = "text-brown" />
                                    </span>
                                </div>
                            )
                        })
                    }
                </div>
                <br></br>
                <div className='section-title'>
                    <h4 className='text-black'>Rysunek</h4>
                </div>
                <div className='work-list grid'>
                    {
                        works_rysunek.map((work, index) => {
                            return (
                                <div className='work-item text-center' key = {index} onClick={() => {
                                    setImageOnModal(work, index)
                                    setIsModalOpen(true);
                                }}>
                                    <img src = {work.image} alt = "" />
                                    <span className='work-item-icon'>
                                        <BsPlusLg size = {38} className = "text-brown" />
                                    </span>
                                </div>
                            )
                        })
                    }
                </div>
                <br></br>
                <div className='section-title'>
                    <h4 className='text-black'>Kostium i Scenografia</h4>
                </div>
                <div className='work-list grid'>
                    {
                        works_kostium_i_scenografia.map((work, index) => {
                            return (
                                <div className='work-item text-center' key = {index} onClick={() => {
                                    setImageOnModal(work, index)
                                    setIsModalOpen(true);
                                }}>
                                    <img src = {work.image} alt = "" />
                                    <span className='work-item-icon'>
                                        <BsPlusLg size = {38} className = "text-brown" />
                                    </span>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    </div>
  )
}

export default Work