import header_background from "../media/images/header_background.jpg";
import pattern_00 from "../media/images/pattern_00.png";
import pattern_1 from "../media/images/pattern_1.png";
import services_img_1 from "../media/images/services_img_1.svg";
import services_img_2 from "../media/images/services_img_2.svg";
import services_img_3 from "../media/images/services_img_3.svg";
import services_img_4 from "../media/images/services_img_4.svg";
import services_img_5 from "../media/images/services_img_5.svg";
import services_img_6 from "../media/images/services_img_6.svg";
import stat_img_1 from "../media/images/stat_img_1.png";
import stat_img_2 from "../media/images/stat_img_2.png";
import stat_img_3 from "../media/images/stat_img_3.png";
import stat_img_4 from "../media/images/stat_img_4.png";
import logo_img_1 from "../media/images/logo_img_1.png";
import logo_img_2 from "../media/images/logo_img_2.png";
import logo_img_3 from "../media/images/logo_img_3.png";
import logo_img_4 from "../media/images/logo_img_4.png";
import logo_img_5 from "../media/images/logo_img_5.png";
import logo_img_6 from "../media/images/logo_img_6.png";
import work_img_1 from "../media/images/work_img_1.jpg";
import work_img_2 from "../media/images/work_img_2.jpg";
// import work_img_3 from "../media/images/work_img_3.jpg";
// import work_img_4 from "../media/images/work_img_4.jpg";
// import work_img_5 from "../media/images/work_img_5.jpg";
// import work_img_6 from "../media/images/work_img_6.jpg";
import About_Alex from "../media/images/337772032_2537310716409620_5673950435710207205_n.jpg";
import subscribe_background from "../media/images/subscribe_background.jpeg";
import rysunek_1 from "../media/images/rysunek/63.jpg";
import rysunek_2 from "../media/images/rysunek/64.jpg";
import rysunek_3 from "../media/images/rysunek/68.jpg";
import rysunek_4 from "../media/images/rysunek/80.jpg";
import kostium_i_scenografia_1 from "../media/images/kostium_i_scenografia/wampir_miasto_2.png";
import malarstwo_1 from "../media/images/malarstwo/dokumentacja_tryptyk.png";
import malarstwo_2 from "../media/images/malarstwo/IMG_6906_wynik.png";
import malarstwo_3 from "../media/images/malarstwo/IMG_6911_wynik.png";
import malarstwo_4 from "../media/images/malarstwo/z_postacią.png";

const images = {
    header_background, pattern_00, pattern_1, services_img_1, services_img_2, services_img_3, services_img_4, services_img_5, services_img_6, stat_img_1, stat_img_2, stat_img_3, stat_img_4, logo_img_1, logo_img_2, logo_img_3, logo_img_4, logo_img_5, logo_img_6, work_img_1, work_img_2, About_Alex, subscribe_background
}
export default images;

const kostium_i_scenografia = {
    kostium_i_scenografia_1
}
export {kostium_i_scenografia};

const malarstwo = {
    malarstwo_1, malarstwo_2, malarstwo_3, malarstwo_4
}
export {malarstwo};

const rysunek = {
    rysunek_1, rysunek_2, rysunek_3, rysunek_4
}
export {rysunek};