import React from 'react';
import "./Header.css";
import Navbar from '../Navbar/Navbar';
import { FaYoutube, FaFacebookF, FaTwitter, FaInstagram, FaPinterest, FaBehance, FaLinkedin } from 'react-icons/fa';

const Header = () => {
  const toWork = () => {
    document.getElementById('work').scrollIntoView({behavior: "smooth", block: "center", inline: "start"})
  }
  const toContact = () => {
  document.getElementById('contact').scrollIntoView({behavior: "smooth", block: "center", inline: "start"})
  }
  const toTestimonials = () => {
    document.getElementById('testimonials').scrollIntoView({behavior: "smooth", block: "center", inline: "start"})
    }
  const toAboutMe = () => {
    document.getElementById('about').scrollIntoView({behavior: "smooth", block: "center", inline: "start"})
  }


  return (
    <div className='header flex flex-col' id = "header">
        {/* <Navbar /> */}
        <br></br><br></br><br></br>
        <div className='container'>
            <div className='header-content'>
                <h2 className='text-uppercase text-center text-black op-07 fw-6 ls-2 header-title'>Aleksandra Kmita</h2>
                <h1 className='text-black text-center fw-6 '>portfolio</h1>
                <div className='btn-groups flex'>
                  <button type = "button" onClick={toWork} className='text-black ls-2 text fw-6 fs-22'>Prace</button>
                  <button type = "button" onClick={toAboutMe} className='text-brown ls-2 text fw-6 fs-22'>O mnie</button>
                  <button type = "button" onClick={toTestimonials} className='text-black ls-2 text fw-6 fs-22'>Wystawy i nagrody</button>
                  <button type = "button" onClick={toContact} className='text-brown ls-2 text fw-6 fs-22'>Kontakt</button>
                </div>
                <div className='btn-groups flex fw-6 fs-14 ls-2'>
                  <a href = "https://www.instagram.com/akmita_/" className='text-black' target="_blank">
                            <FaInstagram />
                  </a>
                  &nbsp;&nbsp;&nbsp;
                  <a href = "https://www.facebook.com/profile.php?id=100090936743852 " className='text-black' target="_blank">
                            <FaFacebookF />
                  </a>
                  &nbsp;&nbsp;&nbsp;
                  <a href = "https://www.youtube.com/channel/UCfdNTjkgAWZR_FtCthlyfGA" className='text-black' target="_blank">
                            <FaYoutube />
                  </a>
                  &nbsp;&nbsp;&nbsp;
                  <a href = "https://www.linkedin.com/in/aleksandra-kmita-a84725227/?fbclid=IwAR0xU0HPf96IguTOHRo7Q0HHCcuUbQ4EFPnHP9wiAPc6MTEQmi9TiaIe448" className='text-black' target="_blank">
                            <FaLinkedin />
                  </a>
                </div>
            </div>
        </div>
        <br></br><br></br><br></br>
    </div>

  )
}

export default Header