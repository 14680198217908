import React from 'react';
import "./Footer.css";
import { FaYoutube, FaFacebookF, FaTwitter, FaInstagram, FaPinterest, FaBehance } from 'react-icons/fa';

const Footer = () => {
  return (
    <div className='footer bg-dark section-p' id = "footer">
        <div className='container'>
            <div className='footer-content'>
                <ul className='footer-social-links flex flex-c'>
                    <li>
                        <a href = "https://www.instagram.com/akmita_/" className='text-white' target="_blank">
                            <FaInstagram />
                        </a>
                    </li>
                    <li>
                        <a href = "https://www.facebook.com/profile.php?id=100090936743852 " className='text-white' target="_blank">
                            <FaFacebookF />
                        </a>
                    </li>
                    <li>
                        <a href = "https://www.youtube.com/channel/UCfdNTjkgAWZR_FtCthlyfGA" className='text-white' target="_blank">
                            <FaYoutube />
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
  )
}

export default Footer