import images from "./images";
import {kostium_i_scenografia} from "./images";
import {malarstwo} from "./images";
import {rysunek} from "./images";

const services = [
    {
        image: `${images.services_img_4}`,
        title: "Creations",
        paragraph: "Vivamus a accumsan mauris. Nunc sodales lacus nec felis suscipit dictum. Mauris auctor enim in justo euismod imperdiet."
    },
    {
        image: `${images.services_img_5}`,
        title: "Illustrations",
        paragraph: "Vivamus a accumsan mauris. Nunc sodales lacus nec felis suscipit dictum. Mauris auctor enim in justo euismod imperdiet."
    },
    {
        image: `${images.services_img_6}`,
        title: "Graphics",
        paragraph: "Vivamus a accumsan mauris. Nunc sodales lacus nec felis suscipit dictum. Mauris auctor enim in justo euismod imperdiet."
    },
];

const works = [
    {
        image: `${images.work_img_1}`,
        title: 'Title',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum'
    },
    {
        image: `${images.work_img_2}`,
        title: 'Title',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum'
    },
];

const works_kostium_i_scenografia = [
    {
        image: `${kostium_i_scenografia.kostium_i_scenografia_1}`,
        title: 'Kostium dla postaci wampirycznej',
        description: ''
    }
];

const works_malarstwo = [
    {
        image: `${malarstwo.malarstwo_1}`,
        title: 'Martwa natura. Tryptyk.',
        description: '150x85 cm, akryl na płótnie, pasta strukturalna'
    },
    {
        image: `${malarstwo.malarstwo_2}`,
        title: 'Yookai i moja przestrzeń domowa. Instalcja malarska',
        description: 'Praca licencjacka. 240x170 cm i 220x170 cm, akryl na płótnie, płyty plexi '    
    },
    {
        image: `${malarstwo.malarstwo_3}`,
        title: 'Yookai i moja przestrzeń domowa. Instalcja malarska',
        description: 'Praca licencjacka. 240x170 cm i 220x170 cm, akryl na płótnie, płyty plexi '
    },
    {
        image: `${malarstwo.malarstwo_4}`,
        title: 'Yookai i moja przestrzeń domowa. Instalcja malarska',
        description: 'Praca licencjacka. 240x170 cm i 220x170 cm, akryl na płótnie, płyty plexi '
    },
];

const works_rysunek = [
    {
        image: `${rysunek.rysunek_1}`,
        title: 'Cykl rysunków domowych',
        description: 'Tusz na papierze'
    },
    {
        image: `${rysunek.rysunek_2}`,
        title: 'Cykl rysunków domowych',
        description: 'Tusz na papierze'
    },
    {
        image: `${rysunek.rysunek_3}`,
        title: 'Cykl rysunków domowych',
        description: 'Tusz na papierze'
    },
    {
        image: `${rysunek.rysunek_4}`,
        title: 'Cykl rysunków domowych',
        description: 'Tusz na papierze'
    },
];

const work_process = [
    {
        title: "Sketch",
        paragraph: "Nunc sodales lacus nec felis suscipit dictum"
    },
    {
        title: "Design",
        paragraph: "Nunc sodales lacus nec felis suscipit dictum"
    },
    {
        title: "Develop",
        paragraph: "Nunc sodales lacus nec felis suscipit dictum"
    },
    {
        title: "End-Product",
        paragraph: "Nunc sodales lacus nec felis suscipit dictum"
    },
];

const about_stats = [
    {
        image: `${images.stat_img_1}`,
        value: "0",
        title: "Clients"
    },
    {
        image: `${images.stat_img_2}`,
        value: "20",
        title: "Projects"
    },
    {
        image: `${images.stat_img_3}`,
        value: "0",
        title: "Working Hours"
    },
    {
        image: `${images.stat_img_4}`,
        value: "0",
        title: "Awards"
    },
];

const testimonials = [
    {
        name: "Wystawa Od martwej natury do sztucznej inteligencji",
        post: "Łódź, Galeria ASP, ul. Piotrkowska 68",
        paragraph: "Galeria ASP, r.2022"
    },
    {
        name: "Wystawa 12x12x12+Nature w ramach Nature Art Cube Exhibition",
        post: "Geumgang Nature Art Biennale 2023",
        paragraph: "Korea Południowa, r.2023"
    },
    {
        name: "Wystawa Moving Nature Art",
        post: "Yeonho Culture Center",
        paragraph: "Korea Południowa, r.2023"
    },
    {
        name: "Wystawa Laureatów 40. Konkursu im. Władysława Strzemińskiego Sztuki Piękne 2023",
        post: "Łódź, Ośrodek Propagandy Sztuki",
        paragraph: "Miejska Galeria Sztuki w Łodzi, r.2023"
    },
    {
        name: "Nagroda Magazynu Kalejdoskop 40. Konkurs im. Władysława Strzemińskiego Sztuki Piękne 2023",
        post: "Łódź, Ośrodek Propagandy Sztuki",
        paragraph: "Miejska Galeria Sztuki w Łodzi, r.2023"
    },
]

const logos = [
    {
        image: `${images.logo_img_1}`
    },
    {
        image: `${images.logo_img_2}`
    },
    {
        image: `${images.logo_img_3}`
    },
    {
        image: `${images.logo_img_4}`
    },
    {
        image: `${images.test_img_5}`
    },
    {
        image: `${images.test_img_6}`
    },
];

export {services, works, works_kostium_i_scenografia, works_malarstwo, works_rysunek, work_process, about_stats, testimonials, logos};
